import React from "react";
import { useTranslation } from "react-i18next";
import footerImg from "../images/landing/footer-img.jpg";

import logo from "../images/logoTY.png";

const ThankYou2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="thankyou-wrapper text-center">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
          <span className="text-themee">{t("thankyou.grazie")}</span>
          <p>{t("thankyou2.p")}</p>
          <a href="/" title="" className="thnk-btn">
            {t("thankyou.btn")}
          </a>
        </div>
      </div>
      <footer className="thnk-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>{t("landing.footer.1")}</p>
            <img src={footerImg} alt="" className="img-fluid" />
            <p>{t("landing.footer.2")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ThankYou2;

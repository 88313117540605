import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

import logo from "../images/landing/logo.png";
import cimg1 from "../images/c-img1.png";
import cimg2 from "../images/c-img2.png";
import cimg3 from "../images/c-img3.png";
import cimg4 from "../images/c-img4.png";
import cimg5 from "../images/c-img5.png";
import cimg6 from "../images/c-img6.png";
import cimg7 from "../images/c-img7.png";
import cimg8 from "../images/c-img8.png";
import cimg9 from "../images/c-img9.png";
import footerImg from "../images/landing/footer-img.jpg";

const ConfermaDatiIT = () => {
  const [isActive, setIsActive] = useState(false);
  const lng = useLanguage();
  let history = useHistory();
  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    emailPaypal: "",
    snaiUser: "",
    snaiNick: "",
    snaiNumConto: "",
    _888User: "",
    bwinUser: "",
    gdUser: "",
    starsUser: "",
    fantasyUser: "",
    newGiocoUser: "",
    newGiocoNick: "",
    planetUser: "",
    planetID: "",
    partyUser: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInput) => ({ ...prevInput, [name]: value }));
  };
  const sendForm = (e) => {
    e.preventDefault();
    setIsActive(true);
    emailjs
      .send(
        "conferma-dati",
        "template_j0t84h7",
        inputs,
        "user_tucYfIrM5kTfzlKhMDvir"
      )
      .then(
        (response) => {
          history.push(`/${lng}/thank-you2`);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <>
      <section className="confir-logo-area">
        <div className="container">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
        </div>
      </section>

      <section className="pt-5 reg-data-sec">
        <div className="container">
          <div className="resgister-data-txt text-center">
            <p>
              Per inviare i tuoi dati di registrazione, ti preghiamo di
              compilare correttamente <strong>tutti i campi qui</strong> sotto.
            </p>

            <p>
              Dopo l’invio dei dati dovrai{" "}
              <strong>attendere circa 24/48 ore per la verifica.</strong> Appena
              la verifica sará completata, riceverai una nostra comunicazione
              con tutte le istruzioni per andare avanti.
            </p>
          </div>
          <div className="reg-data-form text-center">
            <form onSubmit={sendForm}>
              <div className="reg-form-item">
                <h2 className="reg-form-title">Dati Personali</h2>
                <p className="reg-tag-line">I campi con (*) sono OBBLIGATORI</p>
                <input
                  type="text"
                  placeholder="Nome (*)"
                  name="name"
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Cognome (*)"
                  name="surname"
                  value={inputs.surname}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  placeholder="Email (*)"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  placeholder="E-mail account Paypal"
                  name="emailPaypal"
                  value={inputs.emailPaypal}
                  onChange={handleChange}
                />
                <p className="reg-field-txt">
                  L'account Paypal é{" "}
                  <strong>
                    necessario per poter ricevere il bankroll iniziale.
                  </strong>
                  Se non ne hai uno, puoi crearlo facilmente in meno di 5 minuti
                  cliccando{" "}
                  <a
                    href="https://www.paypal.it"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    qui.
                  </a>
                  Nel caso tu ti sia{" "}
                  <strong>registrato solo a New Gioco o PlanetWin,</strong> non
                  é necessario crearne uno.
                </p>
              </div>
              <div className="reg-form-item">
                <h2 className="reg-form-title">Dati Poker Room</h2>
                <p className="reg-tag-line">
                  <span>ATTENZIONE!</span> Compila SOLO i campi relativi alle
                  Room alle quali ti sei iscritto.
                </p>
                <div className="reg-room-item">
                  <img src={cimg1} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="snaiUser"
                    value={inputs.snaiUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Nickname"
                    name="snaiNick"
                    value={inputs.snaiNick}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Numero Conto"
                    name="snaiNumConto"
                    value={inputs.snaiNumConto}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="_888User"
                    value={inputs._888User}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg3} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="bwinUser"
                    value={inputs.bwinUser}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg4} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="gdUser"
                    value={inputs.gdUser}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg5} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="starsUser"
                    value={inputs.starsUser}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg6} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="fantasyUser"
                    value={inputs.fantasyUser}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg7} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="newGiocoUser"
                    value={inputs.newGiocoUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Nickname"
                    name="newGiocoNick"
                    value={inputs.newGiocoNick}
                    onChange={handleChange}
                  />
                </div>
                <div className="reg-room-item">
                  <img src={cimg8} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="planetUser"
                    value={inputs.planetUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Identificativo*"
                    name="planetID"
                    value={inputs.planetID}
                    onChange={handleChange}
                  />
                  <span>
                    *Il codice identificativo si trova sul sito{" "}
                    <a
                      href="https://www.planetwin365.it"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PlanetWin
                    </a>{" "}
                    cliccando su “il mio Conto” —&gt; “Dati Utente”.
                  </span>
                </div>
                <div className="reg-room-item">
                  <img src={cimg9} alt="" />
                  <input
                    type="text"
                    placeholder="Username"
                    name="partyUser"
                    value={inputs.partyUser}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="reg-form-item">
                <p className="reg-tag-line">
                  Per inviare i dati clicca <span>UNA SOLA VOLTA</span> il
                  pulsante e attendi il caricamento della pagina.
                </p>
              </div>
              <button type="submit" disabled={isActive}>
                Conferma i Dati
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer className="confir-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>
              Il gioco è vietato ai minori di 18 anni. La pratica del gioco con
              vincite in denaro può causare dipendenza patologica. Informati
              sulle probabilità di vincita e sul regolamento di gioco sul sito
              www.aams.gov.it (Avvertenze ai sensi del D.L. 13 settembre 2012
              n.158 convertito in Legge n. 189/2012) Consigliamo, inoltre, di
              consultare le probabilità di vincita dei singoli giochi presenti
              sui siti dei concessionari.
            </p>
            <img src={footerImg} alt="" className="img-fluid" />
            <p>
              Copyright © 2005 – 2020 Pokermagia.com – Tutti i diritti
              riservati. Tutto il materiale presente nel sito è coperto da
              Copyright e ne è vietata la riproduzione, anche parziale.
              Pokermagia.com è un sito d’informazione indipendente posseduto da
              Bitmobi Ltd, 3rd Floor, Navi Building, Pantar Road, Lija Lja 2021,
              Malta, Vat Mt 24424511.Pokermagia.com non è un operatore di gioco
              a distanza, ma un portale informativo dedicato ai giochi. Tutte le
              poker room partner del nostro portale hanno regolare licenza AAMS.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConfermaDatiIT;

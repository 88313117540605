import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import contactImg from "../images/contact-img.png";

const Contact = () => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInput) => ({ ...prevInput, [name]: value }));
  };
  const sendForm = (e) => {
    e.preventDefault();
    setIsActive(true);
    emailjs
      .send(
        "conferma-dati",
        "template_5oixl6h",
        inputs,
        "user_tucYfIrM5kTfzlKhMDvir"
      )
      .then(
        (response) => {
          setSent(true);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <>
      <Navbar />
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <div className="caption-title">
                <h1>{t("contact.title")}</h1>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src={contactImg}
                alt=""
                className="img-fluid d-lg-block d-none"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="contact-main">
        <div className="container">
          <div className="caption d-none d-lg-block"></div>
          <div className="row">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="caption d-block d-lg-none"></div>
              <form onSubmit={sendForm}>
                <div className="form-group">
                  <label>{t("contact.name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={handleChange}
                    value={inputs.name}
                    required
                  />
                </div>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      value={inputs.email}
                      required
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>{t("contact.phone")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      onChange={handleChange}
                      value={inputs.phone}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>{t("contact.msg")}</label>
                  <textarea
                    cols="30"
                    rows="6"
                    className="form-control"
                    name="message"
                    onChange={handleChange}
                    value={inputs.message}
                    required
                  ></textarea>
                </div>
                <button className="btn cta-secondary" disabled={isActive}>
                  {t("contact.send")}
                </button>
              </form>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="chat-text">
                <p>{t("contact.sideText")}</p>
              </div>
            </div>
          </div>
          {sent && <h3>{t("contact.sent")}</h3>}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;

import React, {useEffect} from "react";
import { Trans, useTranslation } from "react-i18next";
import footerImg from "../images/landing/footer-img.jpg";
import logo from "../images/logoTY.png";
import { useLocation } from "react-router-dom";

const PlayerAlreadyRegisteredWa = () => {
  const { t } = useTranslation();
  const location = useLocation();


  useEffect(() => {
    const script = document.createElement('script')
    script.text = "fbq('track', 'Lead', {currency: 'EUR', value: 0.00});";
    document.head.appendChild(script);
  }, [location.pathname])

  return (
    <>
      <div className="container">
        <div className="thankyou-wrapper text-center">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
          <span style={{color: "red"}} className="text-themee">{t("playeralreadyregistered.title")}</span>
          <p>
            <Trans t={t}>
              {t("playeralreadyregisteredwa.error")}
              <a target="_blank" href="https://wa.me/+393517158350" style={{color: "#1DAA61"}}> {t("playeralreadyregisteredwa.error2")}</a>
            </Trans>
          </p>
         
        </div>
      </div>
      <footer className="thnk-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>{t("landing.footer.1")}</p>
            <img src={footerImg} alt="" className="img-fluid" />
            <p>{t("landing.footer.2")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PlayerAlreadyRegisteredWa;

import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

import logo from "../images/landing/logo.png";
import rimg1 from "../images/r-img1.png";
import rimg2 from "../images/r-img2.png";
import rimg3 from "../images/r-img3.png";
import cimg2 from "../images/c-img2.png";
import iimg2 from "../images/i-img2.png";
import iimg3 from "../images/i-img3.png";
import cimg5 from "../images/c-img5.png";

const ConfermaDatiES = () => {
  const [isActive, setIsActive] = useState(false);
  const lng = useLanguage();
  let history = useHistory();
  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    specialty: "",
    emailPaypal: "",
    skype: "",
    winamaxUser: "",
    _888User: "",
    betfairUser: "",
    betfairApodo: "",
    partyUser: "",
    luxonNum: "",
    starsUser: "",
    casinoUser: "",
    sportiumUser: "",
    emailSkrill: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInput) => ({ ...prevInput, [name]: value }));
  };
  const sendForm = (e) => {
    e.preventDefault();
    setIsActive(true);
    emailjs
      .send(
        "conferma-dati",
        "template_cp0uvp5",
        inputs,
        "user_tucYfIrM5kTfzlKhMDvir"
      )
      .then(
        (response) => {
          history.push(`/${lng}/thank-you2`);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <>
      <section className="confir-logo-area">
        <div className="container">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
        </div>
      </section>

      <section className="pt-5 reg-data-sec">
        <div className="container">
          <div className="resgister-data-txt text-center">
            <p>
              Para enviar tus datos de registro, rellena{" "}
              <strong>todos los campos</strong> a continuación correctamente.
            </p>

            <p>
              Después de enviar los datos, tendrás que esperar aproximadamente{" "}
              <strong>24/48 horas para la verificación.</strong> Al término de
              la verificación, recibirás una comunicación por parte de nosotros
              con todas las instrucciones para proceder.
            </p>
          </div>
          <div className="reg-data-form text-center">
            <form onSubmit={sendForm}>
              <div className="reg-form-item">
                <h2 className="reg-form-title">Datos Personales</h2>
                <p className="reg-tag-line">
                  Los campos con (*) son OBLIGATORIOS
                </p>
                <input
                  type="text"
                  placeholder="Nombre (*)"
                  name="name"
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Apellido (*)"
                  name="surname"
                  value={inputs.surname}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  placeholder="Correo Electrónico (*)"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Especialidad (Cash Game o Spin&go) (*)"
                  name="specialty"
                  value={inputs.specialty}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="identificación de Skype (*)"
                  name="skype"
                  value={inputs.skype}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  placeholder="Dirección de correo electrónico Paypal"
                  name="emailPaypal"
                  value={inputs.emailPaypal}
                  onChange={handleChange}
                />
                <span className="d-block">
                  Si no tienes cuenta Skype, puedes crearla en unos pocos
                  minutos utilizando{" "}
                  <a href="http://www.skype.com">esto enlace.</a>
                </span>
              </div>
              <div className="reg-form-item mb-5">
                <h2 className="reg-form-title">Datos Salas de Poker</h2>
                <p className="reg-tag-line">
                  <span>ATENÇÃO!</span>Completa solo los campos relacionados a
                  las salas donde te registraste a través de nuestro enlace.
                </p>
                <div className="reg-room-item mb-5">
                  <img src={rimg1} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="winamaxUser"
                    value={inputs.winamaxUser}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Paypal, puedes crearla en unos pocos
                    minutos utilizando{" "}
                    <a href="http://www.paypal.com">esto enlace.</a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={cimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="_888User"
                    value={inputs._888User}
                    onChange={handleChange}
                  />

                  <span>
                    Si no tienes una cuenta Paypal, puedes crearla en unos pocos
                    minutos utilizando{" "}
                    <a href="http://www.paypal.com">esto enlace.</a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={iimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="betfairUser"
                    value={inputs.betfairUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Apodo"
                    name="betfairApodo"
                    value={inputs.betfairApodo}
                    onChange={handleChange}
                  />

                  <span>
                    Si no tienes una cuenta Paypal, puedes crearla en unos pocos
                    minutos utilizando{" "}
                    <a href="http://www.paypal.com">esto enlace.</a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      {" "}
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={iimg3} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="partyUser"
                    value={inputs.partyUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Número de teléfono asociado con Luxonpay (*)"
                    name="luxonNum"
                    value={inputs.luxonNum}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Ecopayz, puedes crearla en unos
                    pocos minutos utilizand
                    <a
                      href="http://www.luxonpay.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={cimg5} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="starsUser"
                    value={inputs.starsUser}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Paypal, puedes crearla en unos pocos
                    minutos utilizando
                    <a
                      href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_61455b_3687c_&affid=58090&siteid=61455&adid=3687&c=Pokermagia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={rimg3} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="casinoUser"
                    value={inputs.casinoUser}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Paypal, puedes crearla en unos pocos
                    minutos utilizando
                    <a
                      href="http://www.paypal.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={rimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="sportiumUser"
                    value={inputs.sportiumUser}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Dirección de correo electrónico Skrill (*)"
                    name="emailSkrill"
                    value={inputs.emailSkrill}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Skrill, puedes crearla en unos pocos
                    minutos utilizando
                    <a
                      href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_61455b_3687c_&affid=58090&siteid=61455&adid=3687&c=Pokermagia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
              </div>
              <div className="reg-form-item">
                <p className="reg-tag-line">
                  Para enviar los datos, haga clic <span> SOLO UMA VEZ</span>en
                  el botón una vez y espere a que se cargue la página.
                </p>
              </div>
              <button type="submit" disabled={isActive}>
                Confirmar los Datos
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer className="confir-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>
              Copyright © 2005-2019 Poekrmagia.com – Todos los derechos
              reservados. Todo el material en el sitio está cubierto por
              Copyright y su reproducción, incluso parcial, está prohibida.
              Pokermagia.com es un sitio de información independiente propiedad
              de Bitmobi Ltd, 3rd Floor, Navi Building, Pantar Road, Lija Lja
              2021, Malta, Vat Mt 24424511.Pokermagia.com no es un operador de
              juegos remoto, sino un portal de información dedicado a los
              juegos. Todos los socios de la sala de póker de nuestro portal
              tienen una licencia AAMS regular.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConfermaDatiES;

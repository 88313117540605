import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

import logo from "../images/landing/logo.png";
import iimg1 from "../images/i-img1.png";
import iimg2 from "../images/i-img2.png";
import iimg3 from "../images/i-img3.png";

const ConfermaDatiPT = () => {
  const [isActive, setIsActive] = useState(false);
  const lng = useLanguage();
  let history = useHistory();
  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    specialty: "",
    skype: "",
    betfairUser: "",
    partyUser: "",
    ggUser: "",
    ecopayz1: "",
    ecopayz2: "",
    ecopayz3: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInput) => ({ ...prevInput, [name]: value }));
  };
  const sendForm = (e) => {
    e.preventDefault();
    setIsActive(true);
    emailjs
      .send(
        "conferma-dati",
        "template_jn6z4l3",
        inputs,
        "user_tucYfIrM5kTfzlKhMDvir"
      )
      .then(
        (response) => {
          history.push(`/${lng}/thank-you2`);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <>
      <section className="confir-logo-area">
        <div className="container">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
        </div>
      </section>

      <section className="pt-5 reg-data-sec">
        <div className="container">
          <div className="resgister-data-txt text-center">
            <p>
              Para enviar seus detalhes de registro,{" "}
              <strong>preencha todos os campos</strong> abaixo corretamente.
            </p>

            <p>
              Depois de enviar os dados, você terá que esperar cerca de{" "}
              <strong>24/48 horas para a verificação.</strong> Assim que a
              verificação for concluída, você receberá uma comunicação nossa com
              todas as instruções para prosseguir.
            </p>
          </div>
          <div className="reg-data-form text-center">
            <form onSubmit={sendForm}>
              <div className="reg-form-item">
                <h2 className="reg-form-title">Dados Pessoais</h2>
                <p className="reg-tag-line">
                  Os campos com (*) são OBRIGATÓRIOS
                </p>
                <input
                  type="text"
                  placeholder="Nome (*)"
                  name="name"
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Sobrenome (*)"
                  name="surname"
                  value={inputs.surname}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  placeholder="Email (*)"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Especialidade (Cash Game ou Spin&go) (*)"
                  name="specialty"
                  value={inputs.specialty}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="ID do Skype"
                  name="skype"
                  value={inputs.skype}
                  onChange={handleChange}
                />
              </div>
              <div className="reg-form-item">
                <h2 className="reg-form-title">Dados Poker Room</h2>
                <p className="reg-tag-line">
                  <span>ATENÇÃO!</span>Preencha SOMENTE os campos relacionados à
                  sala de pôquer em que você se registrou com o nosso link.
                </p>
                <div className="reg-room-item">
                  <img src={iimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Usuário"
                    name="betfairUser"
                    value={inputs.betfairUser}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Endereço de e-mail Ecopayz (*)"
                    name="ecopayz1"
                    value={inputs.ecopayz1}
                    onChange={handleChange}
                  />
                  <span>
                    (*) Se você não possui uma conta Ecopayz, pode criar uma em
                    alguns minutos usando{" "}
                    <a
                      href="http://www.ecopayz.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esse link.
                    </a>
                  </span>
                  <span>
                    Antes de entrar em contato conosco,{" "}
                    <strong>
                      verifique se sua conta está validada e confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item">
                  <img src={iimg1} alt="" />
                  <input
                    type="text"
                    placeholder="Usuário"
                    name="ggUser"
                    value={inputs.ggUser}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Endereço de e-mail Ecopayz (*)"
                    name="ecopayz2"
                    value={inputs.ecopayz2}
                    onChange={handleChange}
                  />
                  <span>
                    (*) Se você não possui uma conta Ecopayz, pode criar uma em
                    alguns minutos usando{" "}
                    <a
                      href="http://www.ecopayz.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esse link.
                    </a>
                  </span>
                  <span>
                    Antes de entrar em contato conosco,{" "}
                    <strong>
                      verifique se sua conta está validada e confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item">
                  <img src={iimg3} alt="" />
                  <input
                    type="text"
                    placeholder="Usuário"
                    name="partyUser"
                    value={inputs.partyUser}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Endereço de e-mail Ecopayz (*)"
                    name="ecopayz3"
                    value={inputs.ecopayz3}
                    onChange={handleChange}
                  />
                  <span>
                    (*) Se você não possui uma conta Luxonpay, pode criar uma em
                    alguns minutos usando{" "}
                    <a
                      href="http://www.ecopayz.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esse link.
                    </a>
                  </span>
                  <span>
                    Antes de entrar em contato conosco,{" "}
                    <strong>
                      verifique se sua conta está validada e confirmada.
                    </strong>
                  </span>
                </div>
              </div>
              <div className="reg-form-item">
                <p className="reg-tag-line">
                  Para enviar os dados, clique<span> APENAS UMA VEZ</span> no
                  botão e aguarde o carregamento da página.
                </p>
              </div>
              <button type="submit" disabled={isActive}>
                Confirmar os Dados
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer className="confir-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>
              Copyright © 2005-2020 Pokermagia.com – Todos os direitos
              reservados. Todo o material do site é coberto por direitos
              autorais e sua reprodução, mesmo parcial, é proibida. O
              Pokermagia.com é um site de informações independente, de
              propriedade da BITMOBI LTD, 3rd floor, Navi Building, Pantar road,
              LIJA, MALTA.
            </p>

            <p>
              O Pokermagia.com não é um operador de jogos a distância, mas um
              portal informativo dedicado a jogos. Todos os nossos parceiros de
              salas de pôquer possuem uma licença AAMS regular.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConfermaDatiPT;

import React, {useEffect, useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import footerImg from "../images/landing/footer-img.jpg";
import logo from "../images/logoTY.png";
import { useLocation } from "react-router-dom";

const ThankYou = () => {
  const { t } = useTranslation();
  const [link, setLink] = useState("");
  const location = useLocation();
  


  useEffect(() => {    
    const script = document.createElement('script')
    script.text = "fbq('track', 'Lead', {currency: 'EUR', value: 0.00});";
    document.head.appendChild(script);

    if (location.pathname === "/it/thank-you") {
      setLink('https://m.me/pokermagia?ref=w20658132');
    }
  
    if (location.pathname === "/es/thank-you" || location.pathname === "/pt/thank-you") {
      setLink('http://m.me/cursosdepoquer');
    }

    setTimeout(function() {
      if (location.pathname === "/it/thank-you") {
        window.location.href = "https://m.me/pokermagia?ref=w20658132"
      }

      if (location.pathname === "/es/thank-you" || location.pathname === "/pt/thank-you") {
        window.location.href = "http://m.me/cursosdepoquer"
      }

      
    }, 14000);
  }, [location.pathname])

  return (
    <>
      <div className="container">
        <div className="thankyou-wrapper text-center">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
          <span className="text-themee">{t("thankyou.grazie")}</span>
          <p>
            <Trans t={t}>{t("thankyou.p")}</Trans>
          </p>
          <a href={link} title="" className="thnk-btn">
            {t("thankyou.btn")}
          </a>
        </div>
      </div>
      <footer className="thnk-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>{t("landing.footer.1")}</p>
            <img src={footerImg} alt="" className="img-fluid" />
            <p>{t("landing.footer.2")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ThankYou;

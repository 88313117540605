import React from 'react';
import { User, MessageSquare, Mail, CreditCard, Tag, Briefcase, HardDrive, Shield, UserCheck, Layout, BarChart2, ExternalLink } from "lucide-react";
import '../css/iubenda.css';

const services = [
  {
    icon: User,
    title: "Acceso a cuentas de terceros",
    items: [
      {
        name: "Acceso a la cuenta de Facebook",
        description: "Permisos: Chat; Datos de uso"
      }
    ]
  },
  {
    icon: MessageSquare,
    title: "Comentarios de contenidos",
    items: [
      {
        name: "Comentarios de Facebook",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: Mail,
    title: "Contactar al Usuario",
    items: [
      {
        name: "Lista de correo o boletín",
        description: "Datos Personales: apellido; correo electrónico; nombre"
      },
      {
        name: "Formulario de contacto",
        description: "Datos Personales: apellido; correo electrónico; nombre; número de teléfono; varios tipos de Datos"
      }
    ]
  },
  {
    icon: CreditCard,
    title: "Gestión de pagos",
    items: [
      {
        name: "PayPal",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: Tag,
    title: "Gestión de etiquetas",
    items: [
      {
        name: "Google Tag Manager",
        description: "Datos Personales: Datos de uso"
      }
    ]
  },
  {
    icon: Briefcase,
    title: "Gestión de páginas de destino y páginas de invitación",
    items: [
      {
        name: "Unbounce",
        description: "Datos Personales: Datos de uso; correo electrónico; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: HardDrive,
    title: "Alojamiento e infraestructura de backend",
    items: [
      {
        name: "Amazon Web Services (AWS)",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: Shield,
    title: "Protección contra SPAM",
    items: [
      {
        name: "Google reCAPTCHA",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: UserCheck,
    title: "Registro y autenticación",
    items: [
      {
        name: "Autenticación de Facebook",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: Layout,
    title: "Estadísticas",
    items: [
      {
        name: "Google Analytics",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      },
      {
        name: "Meta Events Manager",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: BarChart2,
    title: "Visualización de contenido de plataformas externas",
    items: [
      {
        name: "Google Fonts",
        description: "Datos Personales: Datos de uso; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      },
      {
        name: "Widget de Video YouTube",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: ExternalLink,
    title: "Remarketing y targeting conductual",
    items: [
      {
        name: "Remarketing de Facebook",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      },
      {
        name: "Remarketing de Google Ads",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  }
];

const IubendaES = () => {
  return (
    <div className="iubenda-privacy-policy">
      <Header />
      <ServicesSection />
      <AdInfoSection />
      <ContactSection />
      <Footer />
    </div>
  );
};

const Header = () => (
  <>
    <h1 className="iubenda-privacy-policy__title">Política de Privacidad de www.pokermagia.com</h1>
    <p className="iubenda-privacy-policy__subtitle">Esta Aplicación recoge algunos Datos Personales de sus Usuarios.</p>
  </>
);

const ServicesSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Datos Personales tratados para las siguientes finalidades y utilizando los siguientes servicios:</h2>
    <div className="iubenda-privacy-policy__services-grid">
      {services.map((service, index) => (
        <ServiceItem key={index} service={service} />
      ))}
    </div>
  </>
);

const ServiceItem = ({ service }) => (
  <div className="iubenda-privacy-policy__service-item">
    <div className="iubenda-privacy-policy__service-icon">
      <service.icon />
    </div>
    <div className="iubenda-privacy-policy__service-content">
      <h3 className="iubenda-privacy-policy__service-title">{service.title}</h3>
      {service.items.map((item, itemIndex) => (
        <ServiceDetail key={itemIndex} item={item} />
      ))}
    </div>
  </div>
);

const ServiceDetail = ({ item }) => (
  <div className="iubenda-privacy-policy__service-detail">
    <p className="iubenda-privacy-policy__service-name">{item.name}</p>
    <p className="iubenda-privacy-policy__service-description">{item.description}</p>
  </div>
);

const AdInfoSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Información sobre cómo desactivar los anuncios publicitarios basados en intereses</h2>
    <p className="iubenda-privacy-policy__text iubenda-privacy-policy__text--large-margin">
      Además de cualquier función de exclusión voluntaria proporcionada por cualquiera de los servicios enumerados en este documento, los Usuarios pueden obtener más información sobre cómo desactivar los anuncios publicitarios basados en intereses en la sección correspondiente de la Política de Cookies.
    </p>
  </>
);

const ContactSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Información de contacto</h2>
    <div className="iubenda-privacy-policy__contact-info">
      <User className="iubenda-privacy-policy__contact-icon" />
      <div>
        <h3 className="iubenda-privacy-policy__contact-title iubenda-privacy-policy__titolare-title">Titular del Tratamiento de Datos:</h3>
        <div className="iubenda-privacy-policy__address">
          <p>BITMOBI LTD</p>
          <p>135/9 Triq In-Naxxar,</p>
          <p>SGN 9030, San Gwann</p>
          <p>Malta</p>
        </div>
        <p className="iubenda-privacy-policy__email">
          <strong>Dirección de correo electrónico del Titular:</strong> admin@bitmobi.eu
        </p>
      </div>
    </div>
  </>
);

const Footer = () => (
  <>
    <p className="iubenda-privacy-policy__last-modified">Última modificación: 26 de septiembre de 2024</p>
    <p className="iubenda-privacy-policy__disclaimer">
      iubenda aloja este contenido y solo recopila los Datos Personales estrictamente necesarios para su provisión.
    </p>
  </>
);

export default IubendaES;
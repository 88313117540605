import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import footerImg from "../images/landing/footer-img.jpg";
import logo from "../images/logoTY.png";
import { useLocation } from "react-router-dom";

const ThankYouWa = () => {
  const { t } = useTranslation();
  const [link, setLink] = useState("");
  const [linkwa, setLinkWa] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Crea lo script per il tracking di conversione con GTM
    const scriptGTM = document.createElement('script');
    scriptGTM.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('event', 'conversion', {'send_to': 'AW-16531578778/I1HZCKqJ6qYZEJq_78o9'});
    `;
    document.head.appendChild(scriptGTM);

    return () => {
      // Rimuove lo script quando il componente viene smontato
      document.head.removeChild(scriptGTM);
    };
  }, []);

  useEffect(() => {    
    const script = document.createElement('script');
    script.text = "fbq('track', 'Lead', {currency: 'EUR', value: 0.00});";
    document.head.appendChild(script);

    if (location.pathname === "/it/thank-you-wa") {
      setLink('https://m.me/pokermagia?ref=w20658132');
      setLinkWa('https://wa.me/+393517158350');
    }

    if (location.pathname === "/es/thank-you-wa" || location.pathname === "/pt/thank-you-wa") {
      setLink('https://m.me/cursosdepoquer');
    }

    setTimeout(function() {
      if (location.pathname === "/it/thank-you-wa") {
        window.location.href = "https://m.me/pokermagia?ref=w20658132"
      }

      if (location.pathname === "/es/thank-you-wa" || location.pathname === "/pt-thank-you-wa") {
        window.location.href = "https://m.me/cursosdepoquer"
      }

    }, 14000);
  }, [location.pathname]);

  return (
    <>
      <div className="container">
        <div className="thankyou-wrapper text-center">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
          <span className="text-themee">{t("thankyou.grazie")}</span>
          <p>
            <Trans t={t}>{t("thankyou.p")}</Trans>
          </p>
          <a href={link} title="" className="thnk-btn-2">
            {t("thankyou.btn")}
          </a>
          {location.pathname === "/it/thank-you-wa" ? (
            <a href={linkwa} title="" className="thnk-btn-2-wa">
              {t("thankyou.btnwa")}
            </a>
          ) : (
            <span></span>
          )}
        </div>
      </div>
      <footer className="thnk-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>{t("landing.footer.1")}</p>
            <img src={footerImg} alt="" className="img-fluid" />
            <p>{t("landing.footer.2")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ThankYouWa;

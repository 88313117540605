import React from 'react';

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import referImg from "../images/refer-friend-animation.gif";
import referFriendGadget from "../images/refer-friend-gadget.png";
import referFriendGadgetMobile from "../images/refer-friend-gadget-mobile.png";

import { withTranslation } from 'react-i18next';

class ReferFriend extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <>
                <Navbar></Navbar>
                <section className="page-title refer-friend">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-2">
                            <div className="caption-title">
                                <h1>{t("referfriend.title")}</h1>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <img
                                src={referImg}
                                alt=""
                                className="img-fluid d-lg-block d-none"
                            />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="spin">
                    <p>{t("referfriend.description")}</p>
                </section>
                <img
                    src={referFriendGadget}
                    alt=""
                    className="img-fluid d-lg-block d-none"
                    style={{marginTop: "60px"}}
                />
                <img
                    src={referFriendGadgetMobile}
                    alt=""
                    className="img-fluid d-lg-none"
                    style={{margin: "60px auto", display: "block"}}
                />
                <section className="refer-friend-reg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h2>{t("referfriend.titlereg")}</h2>
                                <nav>
                                    <ul>
                                        <li>{t("referfriend.li1")}</li>
                                        <li>{t("referfriend.li2")}</li>
                                        <li>{t("referfriend.li3")}</li>
                                        <li>{t("referfriend.li4")}</li>
                                        <li>{t("referfriend.li5")}</li>
                                        <li>{t("referfriend.li6")}</li>
                                        <li>{t("referfriend.li7")}</li>
                                        <li>{t("referfriend.li8")}</li>
                                        <li>{t("referfriend.li9")}</li>
                                        <li>{t("referfriend.li10")}</li>
                                        <li>{t("referfriend.li11")}</li>
                                        <li>{t("referfriend.li12")}</li>
                                        <li>{t("referfriend.li13")}</li>
                                    </ul>
                                </nav>
                                <a target="_blank" rel="noopener noreferrer" href={t("referfriend.linkregolamento")}>{t("referfriend.regolamento")}</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}
export default withTranslation()(ReferFriend);
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

class Unsub extends React.Component {
   
    render(){
        return(
            <>
                <Navbar></Navbar>
                <div className="container text-center content-landing justify-content-center">
                    <div className="col-lg-8 offset-lg-2">
                        <h1>Disiscrizione effettuata!</h1>
                        <p>Non riceverai più comunicazioni da Pokermagia.</p>
                        {/* <p>Il tuo contatto è stato tolto dal nostro database.</p> */}
                        <br></br>
                    </div>
                </div>
                <Footer></Footer>
            </>
        );
    }
}

export default Unsub;
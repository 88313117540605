import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import mtt from "../images/mtt.png";
import bankroll from "../images/icon-bankroll.svg";
import coaching from "../images/icon-coaching.svg";
import community from "../images/icon-community.svg";

const Mtt = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      {/* <!-- Section Breadcrumb Banner Start --> */}
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="caption-title">
                <h1>MTT</h1>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={mtt} alt="" className="img-fluid d-lg-block d-none" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Breadcrumb Banner End --> */}
      {/* <!-- Section spin Card Start --> */}
      <section className="spin">
        <p>{t("mtt.description")}</p>
      </section>
      {/* <!-- Section spin Card End --> */}
      {/* <!-- Section Punto Start --> */}
      <section className="punto-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card-punto">
                <img src={bankroll} alt="" className="title-img" />
                <h3>{t("mtt.title.punto1")}</h3>
                <p>{t("mtt.punto1")}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card-punto">
                <img src={coaching} alt="" className="title-img" />
                <h3>{t("mtt.title.punto2")}</h3>
                <p>{t("mtt.punto2")}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card-punto">
                <img src={community} alt="" className="title-img" />
                <h3>{t("mtt.title.punto3")}</h3>
                <p>{t("mtt.punto3")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Punto End --> */}
  
      <Footer />
    </>
  );
};

export default Mtt;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import NewsCard from "../components/NewsCard";
import axios from 'axios';

import newsImg from "../images/news-img.png";


const News = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const lng =
    i18n.language.substring(0, 2) === "it"
      ? ""
      : i18n.language.substring(0, 2) === "es"
      ? "/es/"
      : i18n.language.substring(0, 2) === "pt"
      ? "/pt-br/"
      : "";


  useEffect(() => {

    axios.get(process.env.REACT_APP_CRM_API_URL + "/all_news")
      .then(res => {
        console.log(res.data.response);
        setData(res.data.response);
      })

  }, [lng]);
  return (
    <>
      <Navbar />
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="caption-title">
                <h1>News</h1>
                <p>{t("news.title")}</p>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src={newsImg}
                alt=""
                className="img-fluid d-lg-block d-none"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="news-wrapper">
        <div className="container">
          <div className="row">
          {data.length > 0 &&
              data.map((news) => <NewsCard key={news.id} data={news} />)}
          </div>
     
        </div>
      </section>
   
      <Footer />
    </>
  );
};

export default News;

import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import faqImg from "../images/faqs-img.png";

const Faq = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="caption-title">
                <h1>FAQ</h1>
                <p>{t("faq.generaltitle")}</p>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src={faqImg}
                alt=""
                className="img-fluid d-lg-block d-none"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="faqs-main">
        <div className="container">
          <div className="faqs-card">
            <div id="accordion">
              <div className="card">
                <div className="card-header" id="heading1">
                  <a
                    href="#/"
                    className="collapsed faqs_title"
                    data-toggle="collapse"
                    data-target="#collapse1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    <div className="media-body">
                      <h4>{t("faq.title1")}</h4>
                    </div>
                  </a>
                </div>

                <div
                  id="collapse1"
                  className="collapse"
                  aria-labelledby="heading1"
                  data-parent="#accordion"
                >
                  <div className="card-body">{t("faq.answer1")}</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading2">
                  <a
                    href="#/"
                    className="collapsed faqs_title"
                    data-toggle="collapse"
                    data-target="#collapse2"
                    aria-expanded="false"
                    aria-controls="collapse2"
                  >
                    <div className="media-body">
                      <h4> {t("faq.title2")}</h4>
                    </div>
                  </a>
                </div>

                <div
                  id="collapse2"
                  className="collapse"
                  aria-labelledby="heading2"
                  data-parent="#accordion"
                >
                  <div className="card-body">{t("faq.answer2")}</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading3">
                  <a
                    href="#/"
                    className="collapsed faqs_title"
                    data-toggle="collapse"
                    data-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapse3"
                  >
                    <div className="media-body">
                      <h4> {t("faq.title3")}</h4>
                    </div>
                  </a>
                </div>

                <div
                  id="collapse3"
                  className="collapse"
                  aria-labelledby="heading3"
                  data-parent="#accordion"
                >
                  <div className="card-body">{t("faq.answer3")}</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading4">
                  <a
                    href="#/"
                    className="collapsed faqs_title"
                    data-toggle="collapse"
                    data-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                  >
                    <div className="media-body">
                      <h4> {t("faq.title4")}</h4>
                    </div>
                  </a>
                </div>

                <div
                  id="collapse4"
                  className="collapse"
                  aria-labelledby="heading4"
                  data-parent="#accordion"
                >
                  <div className="card-body">{t("faq.answer4")}</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading5">
                  <a
                    href="#/"
                    className="collapsed faqs_title"
                    data-toggle="collapse"
                    data-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapse5"
                  >
                    <div className="media-body">
                      <h4>{t("faq.title5")}</h4>
                    </div>
                  </a>
                </div>

                <div
                  id="collapse5"
                  className="collapse"
                  aria-labelledby="heading5"
                  data-parent="#accordion"
                >
                  <div className="card-body">{t("faq.answer5")}</div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading6">
                  <a
                    href="#/"
                    className="collapsed faqs_title"
                    data-toggle="collapse"
                    data-target="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapse6"
                  >
                    <div className="media-body">
                      <h4>{t("faq.title6")}</h4>
                    </div>
                  </a>
                </div>

                <div
                  id="collapse6"
                  className="collapse"
                  aria-labelledby="heading6"
                  data-parent="#accordion"
                >
                  <div className="card-body">{t("faq.answer6")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section End Start --> */}
      <Footer />
    </>
  );
};

export default Faq;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import ScrollToTop from "./components/ScrollToTop";
import useLanguage from "./hooks/useLanguage";

import Home from "./pages/Home";
import Twitch from "./pages/Twitch";
import Spin from "./pages/Spin";
import Cash from "./pages/Cash";
import Mtt from "./pages/Mtt";
import News from "./pages/News";
import Blog from "./pages/Blog";
import Team from "./pages/Team";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import ConfermaDatiIT from "./pages/ConfermaDatiIT";
import ConfermaDatiES from "./pages/ConfermaDatiES";
import ConfermaDatiPT from "./pages/ConfermaDatiPT";
import ConfermaDatiARG from "./pages/ConfermaDatiARG";
// import Landing from "./pages/Landing";
import LandingWa from "./pages/LandingWa";

import Snai from "./pages/Snai";
import PartyPoker from "./pages/PartyPoker";
import FantasyTeam from "./pages/FantasyTeam";
import Sisal from "./pages/Sisal";
import Pokerstars from "./pages/Pokerstars";
import Lottomatica from "./pages/Lottomatica";
import GiocoDigitale from "./pages/GiocoDigitale";
import Bwin from "./pages/Bwin";
import NewGioco from "./pages/NewGioco";
import OttoOttoOtto from "./pages/OttoOttoOtto";
import Planetwin from "./pages/Planetwin";
import Eurobet from "./pages/Eurobet";

import LatYapoker from "./pages/LatYapoker";
import LatWinamax from "./pages/LatWinamax";
import LatPartyPoker from "./pages/LatPartyPoker";
import LatPokerstars from "./pages/LatPokerstars";
import LatSportium from "./pages/LatSportium";
import Lat888 from "./pages/Lat888";
import LatGGPoker from "./pages/LatGGPoker";
import LatAmCardroom from "./pages/LatAmCardroom";

import LatBetKings from "./pages/LatBetKings";
import LatWpt from "./pages/LatWpt";

import BrPartyPoker from "./pages/BrPartyPoker";
import BrAmCardroom from "./pages/BrAmCardroom";
import BrYapoker from "./pages/BrYapoker";
import BrGGPoker from "./pages/BrGGPoker";
import BrBetfair from "./pages/BrBetfair";

import EsSportium from "./pages/EsSportium";
import EsPokerstars from "./pages/EsPokerstars";
import EsWinamax from "./pages/EsWinamax";
import EsBetfair from "./pages/EsBetfair";
import Es888 from "./pages/Es888";
import EsCasinoBarc from "./pages/EsCasinoBarc";
import EsPartyPoker from "./pages/EsPartyPoker";

import Plexbet from "./pages/Plexbet";

import MyPokerForm from "./pages/MyPokerForm";
import Unsub from "./pages/Unsub";


import ThankYou from "./pages/ThankYou";
import ThankYou2 from "./pages/ThankYou2";
import ThankYouWa from "./pages/ThankYouWa";
import ThankRoom from "./pages/ThankRoom";
import PlayerAlreadyRegistered from "./pages/PlayerAlreadyRegistered";
import PlayerAlreadyRegisteredWa from "./pages/PlayerAlreadyRegisteredWa";


import ReferFriend from "./pages/ReferFriend";

import ItAdmiral from "./pages/ItAdmiral";
import ItWhill from "./pages/ItWhill";
import Iubenda from "./pages/Iubenda";
import IubendaES from "./pages/IubendaES";

const LanguageRoutes = ({ language }) => {
  const { i18n } = useTranslation();
  const lng = useLanguage();
  const location = useLocation();

  let urlLocale = location.pathname.substring(1, 3);
  if (lng !== urlLocale) {
    i18n.changeLanguage(urlLocale);
    return null;
  }

  return (
    <Switch>
      <Route path={`/${language}`} exact>
        <Home />
      </Route>
      <Route path={`/${language}/twitch`} exact>
        <Twitch />
      </Route>
      <Route path={`/${language}/spin`} exact>
        <Spin />
      </Route>
      <Route path={`/${language}/cash`} exact>
        <Cash />
      </Route>
      <Route path={`/${language}/mtt`} exact>
        <Mtt />
      </Route>
      <Route path={`/${language}/news`} exact>
        <News />
      </Route>
      <Route path={`/${language}/news/:id`} component={Blog} />
      <Route path={`/${language}/team-pokermagia`} exact>
        <Team />
      </Route>
      <Route path={`/${language}/faq`} exact>
        <Faq />
      </Route>
      <Route path={`/${language}/contact`} exact>
        <Contact />
      </Route>
      <Route path={`/${language}/conferma-dati-it`} exact>
        <ConfermaDatiIT />
      </Route>
      <Route path={`/${language}/conferma-dati-es`} exact>
        <ConfermaDatiES />
      </Route>
      <Route path={`/${language}/conferma-dati-pt`} exact>
        <ConfermaDatiPT />
      </Route>
      <Route path={`/${language}/conferma-dati-arg`} exact>
        <ConfermaDatiARG />
      </Route>
      <Route path={`/es/landing-2`} exact>
        <LandingWa />
      </Route>
      <Route path={`/es/landing-arg`} exact>
        <LandingWa />
      </Route>
      <Route path={`/landing-wa`} exact>
        <LandingWa />
      </Route>
      <Route path={`/it/landing-wa`} exact>
        <LandingWa />
      </Route>
      <Route path={`/${language}/thank-you`} exact>
        <ThankYou />
      </Route>
      <Route path={`/${language}/thank-you-wa`} exact>
        <ThankYouWa />
      </Route>
      <Route path={`/${language}/thank-you2`} exact>
        <ThankYou2 />
      </Route>

      <Route path={`/${language}/thank-room`} exact>
        <ThankRoom />
      </Route>

      <Route path={`/${language}/player-already-registered`} exact>
        <PlayerAlreadyRegistered />
      </Route>

      <Route path={`/${language}/player-already-registered-wa`} exact>
        <PlayerAlreadyRegisteredWa />
      </Route>

      <Route
        path={`/${language}/snai`}
        component={Snai}
      />

      <Route
        path={`/${language}/party`}
        component={PartyPoker}
      />

      <Route
        path={`/${language}/fantasy`}
        component={FantasyTeam}
      />

      <Route
        path={`/${language}/sisal`}
        component={Sisal}
      />

      <Route
        path={`/${language}/ps`}
        component={Pokerstars}
      />

      <Route
        path={`/${language}/lotto`}
        component={Lottomatica}
      />

      <Route
        path={`/${language}/eurobet`}
        component={Eurobet}
      />

      <Route
        path={`/${language}/gd`}
        component={GiocoDigitale}
      />

      <Route
        path={`/${language}/bwin`}
        component={Bwin}
      />

      <Route
        path={`/${language}/888`}
        component={OttoOttoOtto}
      />

      <Route
        path={`/${language}/newgioco`}
        component={NewGioco}
      />

      <Route
        path={`/${language}/planet`}
        component={Planetwin}
      />

      <Route
        path={`/it/refer-friend`}
        component={ReferFriend}
      />

      <Route
        path={`/es/refer-friend`}
        component={ReferFriend}
      />

      <Route
        path={`/es/yapoker_lat`}
        component={LatYapoker}
      />

      <Route
        path={`/es/winamax_lat`}
        component={LatWinamax}
      />

      <Route
        path={`/es/partypoker_lat`}
        component={LatPartyPoker}
      />

      <Route
        path={`/es/pokerstars_lat`}
        component={LatPokerstars}
      />
      <Route
        path={`/es/betkings_lat`}
        component={LatBetKings}
      />
      <Route
        path={`/es/wpt_lat`}
        component={LatWpt}
      />
      <Route
        path={`/es/sportium_lat`}
        component={LatSportium}
      />

      <Route
        path={`/es/888_lat`}
        component={Lat888}
      />

      <Route
        path={`/it/admiral`}
        component={ItAdmiral}
      />

      <Route
        path={`/it/whill`}
        component={ItWhill}
      />

      <Route
        path={`/es/ggpoker_lat`}
        component={LatGGPoker}
      />

      <Route
        path={`/es/americascardroom_lat`}
        component={LatAmCardroom}
      />

      <Route
        path={`/pt/partypoker`}
        component={BrPartyPoker}
      />

      <Route
        path={`/pt/amcardroom`}
        component={BrAmCardroom}
      />

      <Route
        path={`/pt/yapoker`}
        component={BrYapoker}
      />

      <Route
        path={`/pt/ggpoker`}
        component={BrGGPoker}
      />

      <Route
        path={`/pt/betfair`}
        component={BrBetfair}
      /> 

      <Route
        path={`/es/sportium_es`}
        component={EsSportium}
      /> 

      <Route
        path={`/es/pokerstars_es`}
        component={EsPokerstars}
      />

      <Route
        path={`/es/winamax_es`}
        component={EsWinamax}
      />  

      <Route
        path={`/es/casinobarcelona_es`}
        component={EsCasinoBarc}
      />  

      <Route
        path={`/es/betfair_es`}
        component={EsBetfair}
      />  

      <Route
        path={`/es/888_es`}
        component={Es888}
      />  

      <Route
        path={`/es/partypoker_es`}
        component={EsPartyPoker}
      /> 

      <Route
        path={`/it/mypokerform`}
        component={MyPokerForm}
      /> 

      <Route
        path={`/es/mypokerform`}
        component={MyPokerForm}
      />  

      <Route
        path={`/${language}/plexbet`}
        component={Plexbet}
      />  

      <Route
        path={`/it/unsub`}
        component={Unsub}
      />

      <Route path={`/${language}/privacy-policy`} exact>
        {language === 'es' ? <IubendaES /> : <Iubenda />}
      </Route>
            


    </Switch>
  );
};

function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path={`/landing`} exact>
            <LandingWa />
          </Route>
          <Route path={`/it/landing`} exact>
            <LandingWa />
          </Route>
          <Route path={`/pt-br/landing`} exact>
            <LandingWa />
          </Route>
          <Route exact path="/" render={() => <Redirect to="/it" />} />
          {["it", "es", "pt"].map((language) => (
            <Route
              key={`routes-${language}`}
              path={`/${language}`}
              render={() => <LanguageRoutes language={language} />}
            />
          ))}
        </Switch>
      </Router>
     
    </div>
  );
}

export default App;
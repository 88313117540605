import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import footerImg from "../images/landing/footer-img.jpg";
import logo from "../images/logoTY.png";
import { useLocation } from "react-router-dom";

const PlayerAlreadyRegistered = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Determine the customer care chat link based on the current language
  const getChatLink = () => {
    const currentLanguage = i18n.language;
    if (currentLanguage === "it") {
      return "https://wa.me/+393517158350"; // Italian version link
    } else if (currentLanguage === "es") {
      return "http://m.me/cursosdepoquer"; // Spanish version link
    } else {
      return "https://wa.me/+393517158350"; // Default link or English version
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.text = "fbq('track', 'Lead', {currency: 'EUR', value: 0.00});";
    document.head.appendChild(script);
  }, [location.pathname]);

  return (
    <>
      <div className="container">
        <div className="thankyou-wrapper text-center">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="Logo" />
          </a>
          <span style={{ color: "red" }} className="text-themee">
            {t("playeralreadyregistered.title")}
          </span>
          <p>
            <Trans t={t}>
              {t("playeralreadyregistered.error")}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getChatLink()}
                style={{ color: "#1DAA61" }}
              >
                {t("playeralreadyregistered.error2")}
              </a>
            </Trans>
          </p>
        </div>
      </div>
      <footer className="thnk-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>{t("landing.footer.1")}</p>
            <img src={footerImg} alt="Footer Image" className="img-fluid" />
            <p>{t("landing.footer.2")}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PlayerAlreadyRegistered;

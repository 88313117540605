import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Selection = ({ selectType, type }) => {
  return (
    <ul className="selection">
      <li
        className={`type ${type === "Spin" ? "type-active" : ""}`}
        onClick={selectType}
      >
        Spin
      </li>
      <li
        className={`type ${type === "Cash" ? "type-active" : ""}`}
        onClick={selectType}
      >
        Cash
      </li>
      <li
        className={`type ${type === "MTT" ? "type-active" : ""}`}
        onClick={selectType}
      >
        MTT
      </li>
      <li
        className={`type ${type === "Academy" ? "type-active" : ""}`}
        onClick={selectType}
      >
        Academy
      </li>
    </ul>
  );
};

const CoachSpin = ({ selectType, type }) => {
  const { t } = useTranslation();
  return (
    <section className="our-coaches">
      <div className="container">
        <h2 className="heading text-center">I Nostri Coach</h2>
        <Selection selectType={selectType} type={type} />
        <ul className="coching-list">
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.1")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.1")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.2")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.2")}</p>
              </h4>
            </div>
          </li>

          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.3")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.3")}</p>
              </h4>
            </div>
          </li>
         
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.4")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.4")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.5")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.5")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.6")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.6")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.7")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.7")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.8")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.8")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.9")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.9")}</p>
              </h4>
            </div>
          </li>
         <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.11")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.11")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.12")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.12")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.13")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.13")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.spin.coach.photo.14")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.spin.coach.name.14")}</p>
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

const CoachCash = ({ selectType, type }) => {
  const { t } = useTranslation();
  return (
    <section className="our-coaches">
      <div className="container">
        <h2 className="heading text-center">I Nostri Coach</h2>
        <Selection selectType={selectType} type={type} />
        <ul className="coching-list">
          <li>
            <div className="card-coach">
              <img
                src={t("team.cash.coach.photo.1")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.cash.coach.name.1")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.cash.coach.photo.2")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.cash.coach.name.2")}</p>
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

const CoachMTT = ({ selectType, type }) => {
  const { t } = useTranslation();
  return (
    <section className="our-coaches">
      <div className="container">
        <h2 className="heading text-center">I Nostri Coach</h2>
        <Selection selectType={selectType} type={type} />
        <ul className="coching-list">
          <li>
            <div className="card-coach">
              <img
                src={t("team.mtt.coach.photo.1")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.mtt.coach.name.1")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.mtt.coach.photo.4")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.mtt.coach.name.4")}</p>
              </h4>
            </div>
          </li>
         
        </ul>
      </div>
    </section>
  );
};

const CoachAcademy = ({ selectType, type }) => {
  const { t } = useTranslation();
  return (
    <section className="our-coaches">
      <div className="container">
        <h2 className="heading text-center">I Nostri Coach</h2>
        <Selection selectType={selectType} type={type} />
        <ul className="coching-list">
          <li>
            <div className="card-coach">
              <img
                src={t("team.academy.spin.photo.1")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.academy.spin.name.1")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.academy.spin.photo.2")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.academy.spin.name.2")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.academy.cash.photo.1")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.academy.cash.name.1")}</p>
              </h4>
            </div>
          </li>
          <li>
            <div className="card-coach">
              <img
                src={t("team.academy.mtt.photo.1")}
                alt=""
                className="w-100 img-coach"
              />
              <h4>
                <p>{t("team.academy.mtt.name.1")}</p>
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

const CoachIT = () => {
  const [type, setType] = useState("Spin");
  const selectType = (e) => {
    setType(e.target.innerText);
  };
  if (type === "Spin") {
    return <CoachSpin selectType={selectType} type={type} />;
  }
  if (type === "Cash") {
    return <CoachCash selectType={selectType} type={type} />;
  }
  if (type === "MTT") {
    return <CoachMTT selectType={selectType} type={type} />;
  }
  if (type === "Academy") {
    return <CoachAcademy selectType={selectType} type={type} />;
  }
};

export default CoachIT;

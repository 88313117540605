import React from "react";
import { Link } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";
import { useTranslation } from "react-i18next";

import footerBg from "../images/landing/footer-bg.jpg";
import footerImg from "../images/landing/footer-img.jpg";

const Footer = () => {
  const { t } = useTranslation();
  const lng = useLanguage();

  return (
    <footer className="position-relative landing-footer">
      <div
        className="bg-img"
        style={{ backgroundImage: `url(${footerBg})` }}
      ></div>
      <div className="container">
        <div className="footer-text text-center">
          <p>{t("landing.footer.1")}</p>
          <p>{t("landing.footer.2")}</p>
          {lng === "it" && <img src={footerImg} alt="" className="img-fluid" />}
          <p>{t("landing.footer.3")}</p>
          <p>This site is not part of the Facebook website or Facebook Inc. Additionally, this site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.</p>
        </div>
      </div>
      <div className="copyrights">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            {/* <p>
              Copyright{" "}
            </p> */}
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <ul>
              <li>
                <Link to={`/${lng}/privacy-policy`} rel="nofollow">
                  {lng === "es" ? "Política de Privacidad y Cookies" : "Privacy e Cookie Policy"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

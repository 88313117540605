import React from 'react';
import queryString from 'query-string';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from 'axios';
import logo from "../images/rooms-logos/pokerstars.png";
import { withTranslation } from 'react-i18next';

class EsPokerstars extends React.Component {
    constructor(props) {
        super(props);
        this.email = '';
        this.formIsValid = true;

        this.state = { 
            username: '',  first_name: '', last_name: '', id_conto: '',
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        this.email = values.email
        this.room = values.room
        if(this.email === undefined || this.email === ''){
            window.location.href = "/";
        }
        if(this.room === undefined || this.room === ''){
            window.location.href = "/";
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name] : event.target.value
        })
    }

    handleValidation(){
        const { username, id_conto, first_name, last_name } = this.state 
        const errors = {};
        const { t } = this.props;

        if(!first_name){
            errors["fist_name"] = t("proom.error");
        }

        if(!last_name){
            errors["last_name"] = t("proom.error");
        }

        if(!username){
            errors["username"] = t("proom.error");
        }

        if(!id_conto){
            errors["id_conto"] =  t("proom.error");
        }

        if(!id_conto || !username || !first_name || !last_name){
            this.formIsValid = false;
        }

        this.setState({errors: errors});

        return this.formIsValid;
    }

    handleSubmit(event) {
        event.preventDefault();
        const { t } = this.props;

        if(this.handleValidation()){
            const errors = {};

            const ThankYouPage = `/es/thank-room`;

            const player = {email: this.email, first_name:this.state.first_name, last_name: this.state.last_name}
   
            axios.post(process.env.REACT_APP_CRM_API_URL + "/player/player-account", {
                player: player, 
                player_account_attributes: {
                    poker_room: this.room, player_username: this.state.username,
                    website: "Pokermagia"
                    },
                player_ewallet_attributes: {id_conto: this.state.id_conto, typology: 'Paypal'}
                },
               
            )
            .then(res => {
                if(res.data[0]["status"] === "200"){
                    window.location.href = ThankYouPage;
                }else if(res.data[0]["status"] === "409"){
                    const message = t("proom.account.esistente");
                    errors["response"] = message;
                    this.setState({errors: errors});
                }else{
                    console.log(res);
                }
            }) 
            
        }
    }
  
    
    render() {
      const { t } = this.props;
      return (
        <>
            <Navbar></Navbar>

            <div className="container text-center content-landing">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <p>{t("proom.p1")}</p>
                        <p>{t("proom.p2")}</p>
                        
                        <img className="logo-room" src={logo} alt="" />
                        
                        <div className="col-lg-6 offset-lg-3">
                            <form className="landing" onSubmit={this.handleSubmit}>  
                                <input className="form-control"   type="text" name="first_name" placeholder={t("proom.first_name")} value={this.state.fist_name}  onChange={this.handleChange} />        
                                <span style={{color: "red"}}>{this.state.errors["first_name"]}</span>
                                <br></br>

                                <input className="form-control"   type="text" name="last_name" placeholder={t("proom.last_name")} value={this.state.last_name}  onChange={this.handleChange} />        
                                <span style={{color: "red"}}>{this.state.errors["last_name"]}</span>
                                <br></br>
                                
                                <input className="form-control"   type="text" name="username" placeholder="Username" value={this.state.username}  onChange={this.handleChange} />        
                                <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                                <br></br>
                                <input className="form-control" type="text" name="id_conto" placeholder="E-mail Paypal" value={this.state.id_conto} onChange={this.handleChange} />        
                                <span style={{color: "red"}}>{this.state.errors["id_conto"]}</span>
                                <br></br>
                                <input className="theme-btn d-block" type="submit" value={t("proom.button")} />

                                <span style={{color: "red"}}>{this.state.errors["response"]}</span>

                            </form>
                        </div>
                        
                    </div>
                </div> 

            </div>
            
            <Footer></Footer>
            
        </>
      );
    }
    
}
export default withTranslation()(EsPokerstars);
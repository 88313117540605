import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { withTranslation } from 'react-i18next';

class MyPokerForm extends React.Component {
   
    render(){
        const { t } = this.props;
        return(
            <>
                <Navbar></Navbar>
                <div className="container text-center content-landing justify-content-center">
                    <div className="col-lg-8 offset-lg-2">
                        <h1>{t("mypokerform.title")}</h1>
                        <p>{t("mypokerform.p")}</p>
                        <br></br>
                    </div>
                    <div className="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3">
                        <form action="https://resources.pokermagia.com/register-from-landing" method="POST"
                        >
                            <div>
                                <div className="col-xs-12 col-sm-12 col">
                                    <input
                                        className="form-control"
                                        maxLength="200"
                                        type="text"
                                        name="name"
                                        autoComplete="off"
                                        placeholder={t("mypokerform.name_surname")}
                                        data-required="true"
                                        required
                                    />
                                    <br></br>

                                </div>
                                <div className="col-xs-12 col-sm-12 col">
                                    <input
                                        className="form-control"
                                        maxLength="200"
                                        type="email"
                                        name="email"
                                        autoComplete="off"
                                        placeholder={t("mypokerform.email")}
                                        data-required="true"
                                        required
                                    />
                                    <br></br>

                                </div>

                                <div className="col-xs-12 col-sm-12 col">
                                    <input
                                        className="form-control"
                                        maxLength="200"
                                        type="text"
                                        name="password"
                                        autoComplete="off"
                                        placeholder={t("mypokerform.password")}
                                        data-required="true"
                                        minlength="8"
                                        required
                                    />  
                                    <br></br>

                                </div>

                                <div className="col-xs-12 col-sm-12 col">
                                    <input
                                        className="form-control"
                                        maxLength="200"
                                        type="text"
                                        name="password_confirmation"
                                        autoComplete="off"
                                        placeholder={t("mypokerform.confirm_password")}
                                        data-required="true"
                                        minlength="8"
                                        required
                                    />
                                    <br></br>

                                </div>

                                <div className="col-xs-12 col-sm-12 col">
                                    <select name="speciality" class="form-control">
                                        <option value="">{t("mypokerform.speciality")}</option>
                                        <option value="cash">Cash</option>
                                        <option value="spin">Spin</option>
                                        <option value="mtt">Mtt</option>
                                    </select>

                                    <br></br>

                                </div>

                                <div className="col-xs-12 col-sm-12 col">
                                    <select name="country" class="form-control">
                                        <option value="">{t("mypokerform.country")}</option>
                                        <option value="ITA">ITA</option>
                                        <option value="ESP">ESP</option>
                                    </select>
                                    
                                    <br></br>

                                </div>
                                <div className="form-row justify-content-center">
                                    <div className="col-xs-4 col-sm-4 col">
                                        <button className="theme-btn d-block">
                                            {t("mypokerform.title")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
                <Footer></Footer>
            </>
        );
    }
}

export default withTranslation()(MyPokerForm);
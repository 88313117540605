import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)
import it from "./translations/it.json";
import es from "./translations/es.json";
import pt from "./translations/pt.json";

const options = {
  order: ["localStorage", "navigator"],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    fallbackLng: "it",
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      it: {
        translation: it,
      },
      es: {
        translation: es,
      },
      pt: {
        translation: pt,
      },
    },
    react: {
      wait: true,
    },
    keySeparator: "-",
  });

i18n.changeLanguage();

export default i18n;

import React from "react";
import { TwitchEmbed, TwitchPlayer } from "react-twitch-embed";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// import background from "../images/live-stream.png";

const Twitch = () => {
  return (
    // <!-- Section Breadcrumb Banner Start -->
    <>
      <Navbar />
      <section className="live-video">
        <div className="container">
          <div className="video-card">
            {/* <!-- 16:9 aspect ratio --> */}
            <div className="embed-responsive">
              <TwitchEmbed
                channel="hispano_poker"
                id="hispano_poker"
                theme="dark"
                onVideoPause={() => console.log(":(")}
              />
            </div>
          </div>
        </div>
      </section>
      {/* // <!-- Sectoion Live Video End--> */}
      {/* // <!-- Section Dirct Streaming Start --> */}
      <section className="direct-stream">
        <div className="container">
          <h2 className="heading text-center">Archivio Dirette Streaming</h2>
          <div className="row">
            <div className="col-lg-4 col">
              <div className="video-box">
                <div id="1" className="embed-responsive ">
                  <TwitchPlayer id="1" video="765772313" autoplay={false} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col">
              <div className="video-box">
                <div id="2" className="embed-responsive ">
                  <TwitchPlayer id="2" video="765652016" autoplay={false} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col">
              <div className="video-box">
                <div id="3" className="embed-responsive ">
                  <TwitchPlayer id="3" video="765575644" autoplay={false} />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-6">
              <div className="video-box">
                <div id="4" className="embed-responsive">
                  <TwitchPlayer id="4" video="765306419" autoplay={false} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
     
      <Footer />
    </>
    // <!-- Section Dirct Streaming End -->
  );
};

export default Twitch;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import useLanguage from "./../hooks/useLanguage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import bankroll from "../images/bankroll.png";
import coaching from "../images/coaching.png";
import community from "../images/community.png";
import cash from "../images/cash.png";
import spin from "../images/spin.png";
import tournament from "../images/tournament.png";
import instructor from "../images/instructor.svg";
import pokerplayer from "../images/pokerplayer.svg";
import tutorial from "../images/tutorial.svg";
import trophy from "../images/trophy.svg";
import pokerChip from "../images/poker-chip.png";

const Home = () => {
  const [didCount, setDidCount] = useState(false);
  const lng = useLanguage();
  const { t } = useTranslation();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidCount(true);
    }
  };
  return (
    <div>
      <Navbar />
      <section className="hero-main">
        <div className="container-fluid">
          <div className="hero-text">
            <h1>
              <span>{t("home.header.title1")}</span>
              {t("home.header.title2")}
            </h1>
            <ul>
              <li>
                <img src={pokerChip} alt="" /> {t("home.header.text1")}
              </li>
              <li>
                <img src={pokerChip} alt="" /> {t("home.header.text2")}
              </li>
              <li>
                <img src={pokerChip} alt="" /> {t("home.header.text3")}
              </li>
              <li>
                <img src={pokerChip} alt="" /> {t("home.header.text4")}
              </li>
            </ul>
            <a href={t("link_landing")} className="btn cta-primary">
              {t("home.header.button")}
            </a>
          </div>
        </div>
      </section>
      {/* <!-- HERO SECTION END--> */}
      {/* <!-- Section Bankroll Start --> */}
      <section className="bankroll">
        <div className="container">
        <h2  className="intro-text"> Pokermagia.com è da oltre 10 anni la Scuola di Poker Online leader in Italia. 
          Con le nostre lezioni di Poker personalizzate, ed i nostri 22 coach che seguono gli studenti 24/7, 
          migliorerai le tue tecniche di gioco, vincerai i tuoi primi tornei di Texasholdem online 
          e conoscerai una community di appassionati.
        </h2>  
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-4">
              <div className="card-box">
                <img src={coaching} alt="" className="title-img" />
                <h3>Live Coaching</h3>
                <p>{t("home.livecoaching.text")}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="card-box">
                <img src={bankroll} alt="" className="title-img" />
                <h3>Poker Bonus senza deposito</h3>
                <p>{t("home.bankroll.text")}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="card-box">
                <img src={community} alt="" className="title-img" />
                <h3>Community</h3>
                <p>{t("home.community.text")}</p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a href={t("link_landing")} className="btn cta-secondary">
              {t("navbar.join")}
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Section Bankroll End --> */}
      {/* <!-- SECTION VIDEO ACADEMY START --> */}
      <section className="video-academy">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md">
              <div className="video-holder">
                {/* <!-- 16:9 aspect ratio --> */}
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="Pokermagia Video"
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/G3N662IzO6s?rel=0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- SECTION VIDEO ACADEMY END --> */}
      {/* <!-- Section Speciality Start --> */}
      <section className="speciality">
        <div className="container">
          <div className="title">
            <h2>{t("home.speciality.title")}</h2>
          </div>
          <div className="card-wrapper">
            <div className="row no-gutters">
              <div className="col-lg-4 col-md-4">
                <div className="card-game">
                  <img src={spin} alt="" />
                  <h3>Spin</h3>
                  <p>{t("home.speciality.spin.text")}</p>
                  <Link to={`/${lng}/spin`} className="btn cta-secondary">
                    {t("home.button.scopri")}
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="card-game">
                  <img src={cash} alt="" />
                  <h3>Cash Game</h3>
                  <p>{t("home.speciality.cash.text")}</p>
                  <Link to={`/${lng}/cash`} className="btn cta-secondary">
                    {t("home.button.scopri")}
                  </Link>
                </div>
              </div>
              {lng === "it" && (
                <div className="col-lg-4 col-md-4">
                  <div className="card-game">
                    <img src={tournament} alt="" />
                    <h3>MTT</h3>
                    <p>{t("home.speciality.mtt.text")}</p>
                    <Link to={`/${lng}/mtt`} className="btn cta-secondary">
                      {t("home.button.scopri")}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="content">{t("home.speciality")}</div>
        </div>
      </section>
      {/* <!-- Section Speciality End --> */}
      {/* <!-- Section players info Start --> */}
      <section className="players-info">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="card-counter">
                <div className="card-icon">
                  <img src={instructor} alt="" />
                </div>
                <h4>
                  <span className="counter">
                    <Waypoint onEnter={onVisibilityChange}>
                      <div>
                        <CountUp
                          start={0}
                          end={didCount ? 30 : 0}
                          duration={5}
                        />
                      </div>
                    </Waypoint>
                  </span>
                </h4>
                <p>{t("home.numbers.1")}</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="card-counter">
                <div className="card-icon">
                  <img src={pokerplayer} alt="" />
                </div>
                <h4>
                  <span className="counter">
                    <Waypoint onEnter={onVisibilityChange}>
                      <div>
                        <CountUp
                          start={0}
                          end={didCount ? 290 : 0}
                          duration={5}
                        />
                      </div>
                    </Waypoint>
                  </span>
                </h4>
                <p>{t("home.numbers.2")}</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="card-counter">
                <div className="card-icon">
                  <img src={tutorial} alt="" />
                </div>
                <h4>
                  <span className="counter">
                    <Waypoint onEnter={onVisibilityChange}>
                      <div>
                        <CountUp
                          start={0}
                          end={didCount ? 50 : 0}
                          duration={5}
                        />
                      </div>
                    </Waypoint>
                  </span>
                </h4>
                <p>{t("home.numbers.3")}</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="card-counter">
                <div className="card-icon">
                  <img src={trophy} alt="" />
                </div>
                <h4>
                  <span className="counter">
                    <Waypoint onEnter={onVisibilityChange}>
                      <div>
                        <CountUp
                          start={0}
                          end={didCount ? 7 : 0}
                          duration={5}
                        />
                        +
                      </div>
                    </Waypoint>
                  </span>
                </h4>
                <p>{t("home.numbers.4")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section players info End --> */}
      {/* <!-- Section About Us Start --> */}
      <section className="about-us">
        <h2>{t("home.opinioni")}</h2>
        <Slider {...settings}>
          <div className="content-about">
            <img src={t("home.feedback.photo.1")} alt="" />
            <span>{t("home.feedback.name.1")}</span>
            <p>{t("home.feedback.text.1")}</p>
          </div>
          <div className="content-about">
            <img src={t("home.feedback.photo.2")} alt="" />
            <span>{t("home.feedback.name.2")}</span>
            <p>{t("home.feedback.text.2")}</p>
          </div>
          <div className="content-about">
            <img src={t("home.feedback.photo.3")} alt="" />
            <span>{t("home.feedback.name.3")}</span>
            <p>{t("home.feedback.text.3")}</p>
          </div>
          <div className="content-about">
            <img src={t("home.feedback.photo.4")} alt="" />
            <span>{t("home.feedback.name.4")}</span>
            <p>{t("home.feedback.text.4")}</p>
          </div>
          <div className="content-about">
            <img src={t("home.feedback.photo.5")} alt="" />
            <span>{t("home.feedback.name.5")}</span>
            <p>{t("home.feedback.text.5")}</p>
          </div>
          <div className="content-about">
            <img src={t("home.feedback.photo.6")} alt="" />
            <span>{t("home.feedback.name.6")}</span>
            <p>{t("home.feedback.text.6")}</p>
          </div>
          <div className="content-about">
            <img src={t("home.feedback.photo.7")} alt="" />
            <span>{t("home.feedback.name.7")}</span>
            <p>{t("home.feedback.text.7")}</p>
          </div>
        </Slider>
      </section>
      {/* <!-- Section About Us End --> */}
     
      <Footer />
    </div>
  );
};

export default Home;

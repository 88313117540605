import React from "react";
import { Link } from "react-router-dom";
import useLanguage from "./../hooks/useLanguage";

const NewsCard = ({ data }) => {
  const lng = useLanguage();
  return (
    <>
      <div className="col-xl-3 col-6">
        <div className="news-card">
          <img src={data.image} alt="" className="title-img" />
          <div className="card_body">
            {data.translation.map((translation, i) => { 
              if(translation.language.toLowerCase() === lng){
                return <h3>{translation.title}</h3>
              }else{
                return (null)
              }
               
            })}
            
            <ul className="date-view">
              <li>{ (new Date(data.data)).toLocaleString('it-IT').split(' ')[0].split(',')[0]}</li>
            </ul>
            <Link to={`/${lng}/news/${data.id}`} className="btn cta-secondary">
              Leggi la news
            </Link>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default NewsCard;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import parse from 'html-react-parser';

const Blog = (props) => {
  const { i18n } = useTranslation();
  const [data, setData] = useState([]);
  
  const lng =
    i18n.language.substring(0, 2) === "it"
      ? "it"
      : i18n.language.substring(0, 2) === "es"
      ? "/es/"
      : i18n.language.substring(0, 2) === "pt"
      ? "/pt-br/"
      : "";

      
    useEffect(() => {

      axios.get("https://www.crmpokermagia.com/api/v1/news/" + props.match.params.id + "/" + lng)
        .then(res => {
          setData(res.data.response);
        })
  
    }, [lng, props.match.params.id]);
  return (
    <>
      <Navbar />
      <section style={{marginTop: 150, marginBottom: 100, textAlign: "center"}}>
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <img src={data.image} alt={data.title}></img>
                <h1>{data.title}</h1>
              </div>
            </div>
            <div className="row" style={{textAlign: "left"}}>
              <div className="col-lg-12 mt-5">
                {/* <p>{parse("'"+data.description+"'")}</p> */}
                {parse((data.description) ? data.description : "")}
              </div>
            </div>
        </div>
      </section>
       

      <Footer />
    </>
  );
};

export default Blog;

import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

import logo from "../images/landing/logo.png";
import rimg1 from "../images/r-img1.png";
import rimg2 from "../images/r-img2.png";
import iimg3 from "../images/i-img3.png";
import iimg1 from "../images/i-img1.png";
import cimg2 from "../images/c-img2.png";

const ConfermaDatiARG = () => {
  const [isActive, setIsActive] = useState(false);
  const lng = useLanguage();
  let history = useHistory();
  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    specialty: "",
    emailSkrill1: "",
    emailSkrill2: "",
    emailSkrill3: "",
    winamaxUser: "",
    _888User: "",
    partyUser: "",
    luxonNum: "",
    ggUser: "",
    ecopayz: "",
    sportiumUser: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInput) => ({ ...prevInput, [name]: value }));
  };
  const sendForm = (e) => {
    e.preventDefault();
    setIsActive(true);
    emailjs
      .send(
        "conferma-dati",
        "template_68ngejv",
        inputs,
        "user_tucYfIrM5kTfzlKhMDvir"
      )
      .then(
        (response) => {
          history.push(`/${lng}/thank-you2`);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <>
      <section className="confir-logo-area">
        <div className="container">
          <a href="/" title="" className="text-center d-block">
            <img src={logo} alt="" />
          </a>
        </div>
      </section>

      <section className="pt-5 reg-data-sec">
        <div className="container">
          <div className="resgister-data-txt text-center">
            <p>
              Para enviar tus datos de registro, rellena{" "}
              <strong>todos los campos</strong> a continuación correctamente.
            </p>

            <p>
              Después de enviar los datos, tendrás que esperar aproximadamente{" "}
              <strong>24/48 horas para la verificación.</strong> Al término de
              la verificación, recibirás una comunicación por parte de nosotros
              con todas las instrucciones para proceder.
            </p>
          </div>
          <div className="reg-data-form text-center">
            <form onSubmit={sendForm}>
              <div className="reg-form-item">
                <h2 className="reg-form-title">Datos Personales</h2>
                <p className="reg-tag-line">
                  Los campos con (*) son OBLIGATORIOS
                </p>
                <input
                  type="text"
                  placeholder="Nombre (*)"
                  name="name"
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Apellido (*)"
                  name="surname"
                  value={inputs.surname}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  placeholder="Correo Electrónico (*)"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Especialidad (Cash Game o Spin&go) (*)"
                  name="specialty"
                  value={inputs.specialty}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="reg-form-item mb-5">
                <h2 className="reg-form-title">Datos Salas de Poker</h2>
                <p className="reg-tag-line">
                  <span>ATENÇÃO!</span>Completa solo los campos relacionados a
                  las salas donde te registraste a través de nuestro enlace.
                </p>
                <div className="reg-room-item">
                  <img src={rimg1} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="winamaxUser"
                    value={inputs.winamaxUser}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Dirección de correo electrónico Skrill (*)"
                    name="emailSkrill1"
                    value={inputs.emailSkrill1}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Skrill, puedes crearla en unos pocos
                    minutos utilizando{" "}
                    <a
                      href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_61455b_3687c_&affid=58090&siteid=61455&adid=3687&c=Pokermagia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={cimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="_888User"
                    value={inputs._888User}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Dirección de correo electrónico Skrill (*)"
                    name="emailSkrill2"
                    value={inputs.emailSkrill2}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Skrill, puedes crearla en unos pocos
                    minutos utilizando{" "}
                    <a
                      href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_61455b_3687c_&affid=58090&siteid=61455&adid=3687&c=Pokermagia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={iimg3} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="partyUser"
                    value={inputs.partyUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Número de teléfono asociado con Luxonpay (*)"
                    name="luxonNum"
                    value={inputs.luxonNum}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes cuenta Luxonpay, puedes crearla en unos pocos
                    minutos utilizando{" "}
                    <a
                      href="http://www.luxonpay.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      {" "}
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={iimg1} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="ggUser"
                    value={inputs.ggUser}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Dirección de correo electrónico Ecopayz (*)"
                    name="ecopayz"
                    value={inputs.ecopayz}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Ecopayz, puedes crearla en unos
                    pocos minutos utilizand{" "}
                    <a
                      href="http://www.ecopayz.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
                <div className="reg-room-item mb-5">
                  <img src={rimg2} alt="" />
                  <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    name="sportiumUser"
                    value={inputs.sportiumUser}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Dirección de correo electrónico Skrill (*)"
                    name="emailSkrill3"
                    value={inputs.emailSkrill3}
                    onChange={handleChange}
                  />
                  <span>
                    Si no tienes una cuenta Skrill, puedes crearla en unos pocos
                    minutos utilizando
                    <a
                      href="http://wlskrill.adsrv.eacdn.com/C.ashx?btag=a_61455b_3687c_&affid=58090&siteid=61455&adid=3687&c=Pokermagia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      esto enlace.
                    </a>
                  </span>
                  <span>
                    Antes de contactarnos,{" "}
                    <strong>
                      asegùrate de que tu cuenta este validada y confirmada.
                    </strong>
                  </span>
                </div>
              </div>
              <div className="reg-form-item">
                <p className="reg-tag-line">
                  Para enviar los datos, haga clic<span> SOLO UMA VEZ</span>en
                  el botón una vez y espere a que se cargue la página.
                </p>
              </div>
              <button type="submit" disabled={isActive}>
                Confirmar los Datos
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer className="confir-footer">
        <div className="container">
          <div className="foter-txt text-center">
            <p>
              Copyright © 2005-2020 Pokermagia.com – Todos os direitos
              reservados. Todo o material do site é coberto por direitos
              autorais e sua reprodução, mesmo parcial, é proibida. O
              Pokermagia.com é um site de informações independente, de
              propriedade da BITMOBI LTD, 3rd floor, Navi Building, Pantar road,
              LIJA, MALTA.
            </p>

            <p>
              O Pokermagia.com não é um operador de jogos a distância, mas um
              portal informativo dedicado a jogos. Todos os nossos parceiros de
              salas de pôquer possuem uma licença AAMS regular.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConfermaDatiARG;

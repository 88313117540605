import React from "react";
import { useTranslation } from "react-i18next";
import useLanguage from "./../hooks/useLanguage";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import coaching from "../images/banner-contact.png";

import CoachIT from "../components/CoachIT";
import CoachES from "../components/CoachES";
import CoachPT from "../components/CoachPT";

const Team = () => {
  const { t } = useTranslation();
  const lng = useLanguage();
  return (
    <>
      <Navbar />
      {/* <!-- Section Breadcrumb Banner Start --> */}
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="caption-title">
                <h1>Team Pokermagia</h1>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src={coaching}
                alt=""
                className="img-fluid d-lg-block d-none"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section coaching staking Start --> */}
      <section className="coaching-staking">
        <div className="container">
          <p className="leadtext">{t("team.text.1")}</p>
        </div>
      </section>
      {/* <!-- Section  coaching staking End --> */}
      {/* <!-- Section Our coaches Start --> */}
      {lng === "it" ? (
        <CoachIT />
      ) : lng === "es" ? (
        <CoachES />
      ) : lng === "pt" ? (
        <CoachPT />
      ) : (
        ""
      )}
   
      <Footer />
    </>
  );
};

export default Team;

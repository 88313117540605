import { useTranslation } from "react-i18next";

function useLanguage() {
  const { i18n } = useTranslation();
  const lng =
    i18n.language.substring(0, 2) === "it"
      ? "it"
      : i18n.language.substring(0, 2) === "es"
      ? "es"
      : i18n.language.substring(0, 2) === "pt"
      ? "pt"
      : "it";
  return lng;
}

export default useLanguage;

import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "i18next";
import useLanguage from "./../hooks/useLanguage";
import { useTranslation } from "react-i18next";

import logo from "../images/logo.png";
import menu from "../images/menu.svg";
import italy from "../images/italy.svg";
import spain from "../images/spain.svg";
import brazil from "../images/brazil.svg";
import chevDown from "../images/chev-down.png";


const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const lng = useLanguage();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    let url = "/" + lng + location.pathname.substring(3);
    history.push(url);
    window.location.reload();
  };
  return (
    <header>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg p-0">
          <div className="brand-holder">
            <Link className="navbar-brand" to={`/${lng}`}>
              <img src={logo} alt="" style={{maxWidth: '150px'}} />
            </Link>
          </div>
          <div className="menu-holder">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src={menu} alt="" />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="active">
                <Link to={`/${lng}`}>Home </Link>
              </li>

              <li className="dropdown">
                <Link
                  to="/"
                  className="toggle_link"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span> Academy</span>{" "}
                  <i>
                    <img src={chevDown} alt="" />
                  </i>
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to={`/${lng}/spin`}>Spin </Link>
                  </li>
                  <li>
                    <Link to={`/${lng}/cash`}>Cash </Link>
                  </li>
                  {lng === "it" && (
                    <li>
                      <Link to={`/${lng}/mtt`}>MTT </Link>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                {lng !== "pt" && (
                  <li>
                      <Link to={`/${lng}/refer-friend`}>Porta un Amico</Link>
                  </li>
                )}
              </li>
              <li>
                <Link to={`/${lng}/team-pokermagia`}>Team</Link>
              </li>
              {/* <li>
                <Link to={`/${lng}/twitch`}>Twitch</Link>
              </li> */}

              <li>
                <Link to={`/${lng}/faq`}>FAQ</Link>
              </li>
              <li>
                <Link to={`/${lng}/news`}>News</Link>
              </li>
              {/* <li>
                <Link to={`/${lng}/contact`}>{t("navbar.contact")}</Link>
              </li>  */}
              <li className="right-links">
                <a
                  href="https://my.pokermagia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn cta-dark"
                >
                  Login
                </a>
              </li>
              <li className="language dropdown right-links">
                <Link
                  to=""
                  className="drop-link"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="icon-flag">
                    {i18n.language.substring(0, 2) === "it" ? (
                      <img src={italy} alt="" />
                    ) : i18n.language.substring(0, 2) === "es" ? (
                      <img src={spain} alt="" />
                    ) : i18n.language.substring(0, 2) === "pt" ? (
                      <img src={brazil} alt="" />
                    ) : (
                      "it"
                    )}
                  </span>{" "}
                  <i>
                    <img src={chevDown} alt="" />
                  </i>{" "}
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                  <button onClick={() => changeLanguage("it")}>
                    <span className="icon-flag">
                      <img src={italy} alt="" />
                    </span>{" "}
                    <small>Italiano</small>{" "}
                  </button>
                  <button onClick={() => changeLanguage("es")}>
                    <span className="icon-flag">
                      <img src={spain} alt="" />
                    </span>{" "}
                    <small>Español</small>{" "}
                  </button>
                  {/* <button onClick={() => changeLanguage("pt")}>
                    <span className="icon-flag">
                      <img src={brazil} alt="" />
                    </span>{" "}
                    <small>Português</small>{" "}
                  </button> */}
                </div>
              </li>
            </ul>
          </div>
          <ul className="right-links">
            <li>
              <a href={t("link_landing")} className="btn cta-primary">
                {t("navbar.join")}
              </a>
            </li>
            <li className="right-links d-none d-lg-block">
              <a
                href="https://my.pokermagia.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn cta-dark"
              >
                Login
              </a>
            </li>
            <li className="language dropdown d-none d-lg-block">
              <Link
                to=""
                className="drop-link"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon-flag">
                  {i18n.language.substring(0, 2) === "it" ? (
                    <img src={italy} alt="" />
                  ) : i18n.language.substring(0, 2) === "es" ? (
                    <img src={spain} alt="" />
                  ) : i18n.language.substring(0, 2) === "pt" ? (
                    <img src={brazil} alt="" />
                  ) : (
                    ""
                  )}
                </span>{" "}
                <i>
                  <img src={chevDown} alt="" />
                </i>{" "}
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <button onClick={() => changeLanguage("it")}>
                  <span className="icon-flag">
                    <img src={italy} alt="" />
                  </span>{" "}
                  <small>Italiano</small>{" "}
                </button>
                <button onClick={() => changeLanguage("es")}>
                  <span className="icon-flag">
                    <img src={spain} alt="" />
                  </span>{" "}
                  <small>Español</small>{" "}
                </button>
                {/* <button onClick={() => changeLanguage("pt")}>
                  <span className="icon-flag">
                    <img src={brazil} alt="" />
                  </span>{" "}
                  <small>Português</small>{" "}
                </button> */}
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;

import React from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


import cashImg from "../images/cash-page.png";
import bankroll from "../images/icon-bankroll.svg";
import coaching from "../images/icon-coaching.svg";
import community from "../images/icon-community.svg";

const Cash = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      {/* <!-- Section Breadcrumb Banner Start --> */}
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="caption-title">
                <h1>Cash</h1>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src={cashImg}
                alt=""
                className="img-fluid d-lg-block d-none"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Breadcrumb Banner End --> */}
      {/* <!-- Section spin Card Start --> */}
      <section className="spin">
        <p>{t("cash.description")}</p>
      </section>
      {/* <!-- Section spin Card End --> */}
      {/* <!-- Section Punto Start --> */}
      <section className="punto-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card-punto">
                <img src={bankroll} alt="" className="title-img" />
                <h3>{t("cash.title.punto1")}</h3>
                <p>{t("cash.punto1")}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card-punto">
                <img src={coaching} alt="" className="title-img" />
                <h3>{t("cash.title.punto2")}</h3>
                <p>{t("cash.punto2")}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card-punto">
                <img src={community} alt="" className="title-img" />
                <h3>{t("cash.title.punto3")}</h3>
                <p>{t("cash.punto3")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section Punto End --> */}
      <Footer />
    </>
  );
};

export default Cash;
